import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  showError (title: string, message: string) {
    this.toastr.error(title, message);
  }

  showSuccess (title: string, message: string) {
    this.toastr.success(title, message);
  }

  showQuestion (title: string, message: string) {
    return Swal.fire({
      title: title,
      text: message,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      icon: 'question',
      showCancelButton: true,
      cancelButtonColor: '#dc3545',
      confirmButtonColor: '#2800d6',
      confirmButtonText: 'Accept'
    })
  }

  showLoading (title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    })
    Swal.showLoading();
  }

  showSwalError(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      icon: 'error'
    });
  }

  hideLoading () {
    Swal.close()
  }

  showRedirectPage (title: string, message: string) {
    return Swal.fire({
      title: title,
      text: message,
      timerProgressBar: true,
      timer: 2000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => Swal.showLoading()
    })
  }
}
